<template>
  <div>
    <el-upload
    ref="upload_Img"
      class="upload-demo"
      :action="BaseUrl"
      :limit='1'
      :auto-upload="true"
      :show-file-list="true"
      :file-list="fileList"
      list-type="picture"
      :headers="headers"
      :on-success="sendUrl"
    >
      <el-button size="small" type="primary">点击上传</el-button>
      <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
    </el-upload>
  </div>
</template>
<script>
import { $baseURL } from "../utils/interceptors.js";
import { constants, setPriority } from 'os';
import { connect } from 'tls';
export default {
  name: "upload",
  data() {
    return {
      artisanSon: this.artisan,
      //   返回父组件的的判断值
      updateValue: "",
      sync: false,
      upExcil: false,
      BaseUrl: this.$store.state.BaseUrl + "admin/upload_img",
      fileList: [],
      fileUrl:'',
      isList:this.$store.state.fileList
    };
  },
  mounted() {
      if(this.isList){
        this.$refs.upload_Img.clearFiles();
        this.fileList=[]
      }
  },
  watch:{
    isList(){
      if(this.isList){
        this.$refs.upload_Img.clearFiles();
        this.fileList=[]
      }
    }
  },
  computed: {
    headers() {
      return {
        Authorization: window.localStorage.getItem("token"),
        version: "v3"
      };
    }
  },
  methods: {
    //   上传文件成功接口
    sendUrl(res) {
      if(res.code==200){
        this.$message({
          type: "success",
          message: '上传成功'
        });
        this.fileUrl = res.data.file[0]
        this.$emit('fileData',this.fileUrl)
        setTimeout(function(){
          this.$refs.upload_Img.clearFiles()
        },3000)
      }else{
        this.$message({
          type: "success",
          message: '上传失败'
        });
        this.$refs.upload_Img.clearFiles();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
 ::v-deep .upload-demo{
   width:27vh;
  }
</style>
