<template>
  <div>
    <div class="ipSubmit_home" :style="{width:widthStyle[2]}">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>系统设置</el-breadcrumb-item>
        <el-breadcrumb-item>广告位设置</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main cardd">
      <div style="margin-bottom: 20px; display:flex;">
        <el-button size="small" @click="dialogAdverVisible=true" style="margin-right: 20px;" type="primary">添加广告位类别</el-button>
        <div style="width:70%; display:flex;">
                <el-input
                  v-model="typeInput"
                  placeholder="请输入要搜索的广告位类别名称"
                  clearable
                  style="width:40%;margin-right:20px"
                ></el-input>
                <el-button @click="getAdverType(typeInput)" type="primary">搜索</el-button>
              </div>
      </div>
      <el-tabs tab-position="left" v-model="editableTabsValue">
        <el-tab-pane v-for="item in editableTabs" :key="item.id" :label="item.name">
          <span slot="label" @click="getAdverList(item)">
            {{item.name}}
            <el-link type="warning" style="margin-left:5px;">{{item.sort}}</el-link>
            <!-- {{item.id}} -->
            <i class="el-icon-edit" style="margin-left:35px;" @click="aduitTab(item)"></i>
            <i class="el-icon-close" @click="removeTab(item.id)"></i>
          </span>
          <div>
            <div style="margin-bottom: 20px; display:flex;justify-content:space-between;">
              <el-button size="small" @click="openAdver(item)" type="warning">添加广告位</el-button>
              <div style="width:50%; display:flex;">
                <el-input
                  v-model="input"
                  placeholder="请输入要搜索的广告位名称"
                  clearable
                  style="width:50%;margin-right:20px"
                ></el-input>
                <el-button @click="getAdverList(input)" type="primary">搜索</el-button>
              </div>
            </div>
            <template>
              <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="fid" label="类别" width="70"></el-table-column>
                <el-table-column prop="name" label="名称" width="100"></el-table-column>
                <el-table-column prop="view_sort" label="顺序" width="50"></el-table-column>
                <el-table-column prop="brief" label="广告位简介"></el-table-column>
                <el-table-column prop="details" label="广告位详情"></el-table-column>
                <el-table-column prop="url" label="跳转路径"></el-table-column>
                <el-table-column prop="is_outer_chain" label="跳转类型" width="100">
                  <template slot-scope="scope">
                    <el-tag
                      :type="scope.row.is_outer_chain === 0 ? 'primary' :'success'"
                      disable-transitions
                    >{{scope.row.is_outer_chain === 0 ? '内链' :'外链'}}</el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="icon" label="广告位图标">
                  <template slot-scope="scope">
                    <el-image style="width: 100px; height: 100px" :src="imgBase + scope.row.icon"></el-image>
                  </template>
                </el-table-column>
                <el-table-column prop="img" label="广告位图片">
                  <template slot-scope="scope">
                    <el-image style="width: 100px; height: 100px" :src="imgBase + scope.row.img"></el-image>
                  </template>
                </el-table-column>
                <el-table-column prop="status" label="是否展示" width="100">
                  <template slot-scope="scope">
                    <el-tag
                      :type="scope.row.status === 0 ? 'primary' :'success'"
                      disable-transitions
                    >{{scope.row.status === 0 ? '隐藏' :'展示'}}</el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop label="操作">
                  <template slot-scope="scope" style="text-align:center">
                    <el-button
                      type="primary"
                      size="mini"
                      @click="openShow(scope.row)"
                      style="margin-right:10px;"
                    >修改</el-button>
                    <el-button
                      type="danger"
                      size="mini"
                      @click="deleteAdver(scope.row)"
                      style="margin-left:0px;"
                    >删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div>
                <Pagenation :size="10" :total="count" v-on:getPage="handleCurrentChange"></Pagenation>
              </div>
            </template>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div>
        <Pagenation
          :size="10"
          :total="typeCount"
          v-on:getPage="typeCurrentChange"
          style="display:flex;justify-content:left;"
        ></Pagenation>
      </div>
      <!-- 修改广告位类别 -->
      <el-dialog :title="adverType ? '修改广告位类别' : '添加广告位类别'" :visible.sync="dialogAdverVisible">
        <el-form ref="form" :model="form">
          <el-form-item label="广告位类别名称：">
            <el-input v-model="adverTypeName" :placeholder="adverTypeName"></el-input>
          </el-form-item>
          <el-form-item label="广告位类别顺序：">
            <el-input v-model="adverTypeOrder" placeholder="请输入该类别的排放顺序数字" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button  @click="dialogAdverVisible=false">取 消</el-button>
            <el-button type="primary" @click="aduitType()">{{adverType ? '修改' : '添加'}}</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <!-- 添加广告位 -->
      <el-dialog :title="adverTitle" :visible.sync="dialogFormVisible">
        <el-form :model="form" label-width="120px">
          <el-form-item label="广告位类别ID：">
            <el-input v-model="form.fid" disabled autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="广告位名称：">
            <el-input v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="广告位简介：">
            <el-input v-model="form.brief" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="广告位顺序：">
            <el-input v-model="form.view_sort" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="广告位详情：">
            <el-input v-model="form.details" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="跳转路径：">
            <el-input v-model="form.url" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="跳转类型：" prop="is_outer_chain">
            <template>
              <el-select v-model="form.is_outer_chain" placeholder="请选择">
              <el-option  label="内链" value="0"></el-option>
              <el-option  label="外链" value="1"></el-option>
              </el-select>
            </template>
          </el-form-item>
          <el-form-item label="是否展示：" prop="status">
            <template>
              <el-select  v-model="form.status" placeholder="请选择">
              <el-option  label="隐藏" value="0"></el-option>
              <el-option  label="展示" value="1"></el-option>
              </el-select>
            </template>
          </el-form-item>
          <el-form-item label="广告位图标：" prop="file">
            <template>
              <div style="display:flex;">
                <el-image
                  v-if="aduit"
                  style="width: 100px; height: 100px;margin-right:30px;"
                  :src="imgBase+form.icon"
                  fit
                ></el-image>
                <upload :listrue="islist" @fileData="iconUpload"></upload>
              </div>
            </template>
          </el-form-item>
          <el-form-item label="广告位图片：" prop="img">
            <template>
              <div style="display:flex;">
                <el-image
                  v-if="aduit"
                  style="width: 100px; height: 100px;margin-right:30px;"
                  :src="imgBase+form.img"
                  fit
                ></el-image>
                <upload @fileData="imgUpload"></upload>
              </div>
            </template>
          </el-form-item>
          <el-form-item>
            <el-button  @click="escList">取 消</el-button>
            <el-button type="primary" @click="addAdver(form)">{{aduit ? '修改' : '添加'}}</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Pagenation from "../components/pagenation";
import upload from "../components/upload";
import { constants } from 'os';
export default {
  data() {
    return {
      BasUrl: this.$store.state.BaseUrl + "admin/upload_img",
      imgBase: this.$store.state.BaseUrl,
      editableTabsValue: "0",
      editableTabs: [],
      count: null,
      typeCount: null,
      dialogAdverVisible: false,
      adverType: false,
      adverTypeName: "",
      TypeName: "",
      adverTypeOrder: "",
      aduitTypeId: "",
      dialogFormVisible: false,
      typeInput:'',
      input: "",
      islist: false,
      form: {
        fid: "",
        name: "",
        brief: "",
        view_sort: "",
        details: "",
        url: "",
        is_outer_chain: "",
        status: "",
        icon: "",
        img: ""
      },
      options: [
        { label: 0, value: "隐藏" },
        { label: 1, value: "展示" }],
      tableData: [],
      typeItem: "",
      fileList: [],
      adverTitle: "",
      formId: "",
      aduit: false,
      aduitId: ""
    };
  },
  watch: {
    input() {
      if (this.input == "") {
        this.getAdverList(this.typeItem, 0);
      }
    }
  },
  components: {
    Pagenation,
    upload
  },
  computed: {
    widthStyle() {
      return this.$store.state.widthStyle;
    },
    headers() {
      return {
        Authorization: window.localStorage.getItem("token"),
        version: "v3"
      };
    }
  },
  mounted() {
    this.AllType(0);
  },
  methods: {
    AllType(page) {
      this.$axios
        .post("/get_advert_type_list", { key_name: "", page: page })
        .then(res => {
          this.typeCount = res.count;
          let data = res.data.result;
          this.editableTabs = data;
          this.getAdverList(this.editableTabs[0],0)
        });
    },
    // 搜索广告位类别
    getAdverType(typeInput){      
      this.$axios
        .post("/get_advert_type_list", { key_name: typeInput, page: 0 })
        .then(res => {
          this.typeCount = res.count;
          let data = res.data.result;
          this.editableTabs = data;
        });
    },
    typeCurrentChange(item) {
      this.AllType(item);
    },
    handleCurrentChange(item) {
      this.getAdverList(this.typeItem, item);
    },
    aduitTab(item) {
      this.adverType = true;
      this.adverTypeOrder = "";
      this.dialogAdverVisible = true;
      this.adverTypeName = item.name;
      this.aduitTypeId = item.id;
    },
    aduitType() {
      if (this.adverTypeName != "" || this.adverTypeOrder != "") {
        if (this.adverType) {
          this.$axios
            .post("/save_advert_type", {
              id: this.aduitTypeId,
              name: this.adverTypeName,
              sort: this.adverTypeOrder
            })
            .then(res => {
              if (res.code == 200) {
                this.dialogAdverVisible = false;
                this.AllType(0);
              }
            });
        } else {
          this.$axios
            .post("/save_advert_type", {
              name: this.adverTypeName,
              sort: this.adverTypeOrder
            })
            .then(res => {
              if (res.code == 200) {
                this.AllType(0);
                this.dialogAdverVisible = false;
              }
            });
        }
      } else {
        this.$message({
          message: "请填写完整！",
          type: "warning"
        });
      }
    },
    removeTab(name) {
      this.$confirm("此操作将永久删除该广告位, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$axios.post("/del_advert_type", { id: name }).then(res => {
            if (res.code == 200) {
              this.AllType(0);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    // 获取广告列表
    getAdverList(item, page) {
      if (typeof item == "string") {
        var searchName = item;
        page = 0;
      } else {
        var searchName = "";
        this.typeItem = item;
        page = 0;
      }
      this.$axios
        .post("/get_advert_list", {
          key_name: searchName,
          key_fid: this.typeItem.id,
          page: page
        })
        .then(res => {
          this.count = res.count;
          this.tableData = res.data.result;
        });
    },
    // 上传图片
    iconUpload(file) {
      this.form.icon = file;
    },
    imgUpload(file) {
      this.form.img = file;
    },
    // 打开添加广告位
    openAdver(fItem) {
      this.aduit = false;
      this.form = {};
      this.$store.commit("setList",true)
      this.adverTitle = "添加广告位";
      this.dialogFormVisible = true;
      this.form.fid = fItem.id;
      this.formId = fItem.id;
    },
    addAdver() {
      this.$store.commit("setList",false)
      if (this.aduit == true) {
        var formData = { id: this.aduitId, ...this.form };
      } else {
        var formData = {
          fid: this.form.fid,
          name: this.form.name,
          brief: this.form.brief,
          view_sort: this.form.view_sort,
          details: this.form.details,
          url: this.form.url,
          is_outer_chain: this.form.is_outer_chain,
          status: this.form.status,
          icon: this.form.icon,
          img: this.form.img
        };
      }
      this.$axios.post("/save_advert", { ...formData }).then(res => {
        if (res.code == 200) {
          this.dialogFormVisible = false;
          this.getAdverList(this.typeItem, 0);
        }
      });
    },
    escList(){
      this.dialogFormVisible=false
      this.$store.commit("setList",false)
    },
    // 修改广告位
    openShow(row) {
      this.aduit = true;
      this.adverTitle = "修改广告位";
      this.dialogFormVisible = true;
      this.aduitId = row.id;
      this.form.fid = row.fid;
      this.form.name = row.name;
      this.form.brief = row.brief;
      this.form.view_sort = row.view_sort;
      this.form.details = row.details;
      this.form.url = row.url;
      // this.form.is_outer_chain = row.is_outer_chain;
      // this.form.status = row.status;
      this.form.icon = row.icon;
      this.form.img = row.img;
    },
    // 删除广告位
    deleteAdver(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$axios.post("/del_advert", { id: row.id }).then(res => {
            if (res.code == 200) {
              this.getAdverList(this.typeItem, 0);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.cardd {
  width: 95% !important;
  margin-top: 60px;
}
::v-deep .el-tabs__item {
  padding: 0 20px 0 0 !important;
}
::v-deep .el-tabs__item .el-icon-close {
  margin-left: 15px;
}
::v-deep .el-dialog {
  width: 30% !important;
}
::v-deep .el-form-item {
  margin: 10px 0 !important;
}
::v-deep .el-form-item__content{
  // margin-left:1vh !important;
}
</style>

